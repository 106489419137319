<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack()"> 
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10104')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- // nav -->
	<div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub guest">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__wrap">
							<div class="guest__description">
								{{t('10105')}}
							</div>
							<div class="frms">
								<!-- 예약번호 -->
								<div class="frms__item">
									<h3 class="frms__title">{{t('10086')}} <span class="dot dot--red"></span></h3>
									<div class="frms__wrap" :class="{failed: isFailed}">
										<input type="text" class="ipt-frm" :placeholder="t('10777')" v-model="bookingNumber">
										<div class="frms__btns" v-if="bookingNumber.length > 0">
											<button type="button" class="btn-frmreset" @click="resetBookingNumber">
												<i class="icon icon-closesmall">{{t('10036')}}</i>
											</button>
										</div>
										<div class="frms__msg" v-if="isFailed">
											{{t('10106')}}
										</div>
									</div>
								</div>
								<!-- 연락처 변경 -->
								<div class="frms__item">
									<h3 class="frms__title">{{t('10092')}}<span class="dot dot--red"></span></h3>
									<div class="frms__wrap">
										<input type="tel" class="ipt-frm" :placeholder="t('10778')" :value="phoneNumber"  @input="updatePhoneNumber($event)"  @keyup="checkNumber($event)" ><!-- 재번역 체크 -->
										<div class="frms__btns" v-if="phoneNumber.length > 0">
											<button type="button" class="btn-frmreset" @click="resetPhoneNumber">
												<i class="icon icon-closesmall">{{t('10036')}}</i>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<!-- [D] 미입력 시 -->
		<!-- <div class="btns btns--centered">
			<button type="button" class="btn btn-disabled">예약조회</button>
		</div> -->
		<!-- [D] 입력 시 -->
		<div class="btns btns--centered">
			<button type="button" class="btn" :class="isVerified ? 'btn-confirm' : 'btn-disabled'" :disabled="!isVerified" @click="handleSubmit">{{t('10107')}}</button>
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>


<script>
import { computed, ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
	setup() {
		const store = useStore();
		const router = useRouter();
		const bookingNumber = ref("");
		const phoneNumber = ref("");
		const isPhoneNumber = ref(false);
		const isFailed = ref(false);
		const { t }= useI18n() //번역필수 모듈

		const updatePhoneNumber = (e) => {
			phoneNumber.value = e.target.value;
		};

		const checkNumber = () => {
			const number = phoneNumber.value.replace(/[^0-9]/g, "");
			let phone = "";

			if(number.length < 4) { 
				phone = number; 
			}
			else if(number.length < 7) { 
				phone += number.substr(0, 3); 
				phone += "-"; phone += number.substr(3); 
			} 
			else if(number.length < 11) { 
				phone += number.substr(0, 3); 
				phone += "-"; phone += number.substr(3, 3); 
				phone += "-"; phone += number.substr(6); 
			} 
			else { 
				phone += number.substr(0, 3); 
				phone += "-"; 
				phone += number.substr(3, 4); 
				phone += "-"; 
				phone += number.substr(7); 
			}

			phoneNumber.value = phone;

			const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
			if(regPhone.test(phone)) {
				isPhoneNumber.value = true;
			} else {
				isPhoneNumber.value = false;
			}

		}

		const goBack = () => {
			router.go(-1);
		};

		const handleSubmit = async () => {
			const status = await store.dispatch("mmy09/fetchMmy0901", {hp_no: phoneNumber.value, booking_no: bookingNumber.value});
			if(status !== "0000") {
				isFailed.value = true;
			}else {
				router.push("/guest/complete");
			}
		}

		return {
			goBack,
			bookingNumber,
			phoneNumber,
			updatePhoneNumber,
			checkNumber,
			resetPhoneNumber: () => {
				phoneNumber.value = "";
				isPhoneNumber.value = false;
			},
			isPhoneNumber,
			isVerified: computed(() => {
				return isPhoneNumber.value && bookingNumber.value.length > 0;
			}),
			resetBookingNumber: () => {
				bookingNumber.value = "";
				isFailed.value = false
			},
			isFailed,
			handleSubmit,
			t,
			i18n,
		}
	},
}
</script>